import axios from "axios";
import { useState } from "react";
import {
  TrashIcon,
  PencilAltIcon
 
} from "@heroicons/react/solid";
import Swal from 'sweetalert2'

import {ROUTE, MESSAGES, DELETE, ACTIVE} from '../../Constant/Route'
import useAuth from "../../Hooks/useAuth";

const RowTableMessages = ({ item }) => {
  const [element, setElemet]= useState(item);
  const {token} = useAuth()

  const active = () =>{
    var url = ROUTE + MESSAGES +'/' + element.id + '/' + ACTIVE;
    const config = { headers: { Authorization: "Bearer " + token() } };
    const body = { status: ! element.status };
    axios
    .post(url, body, config)
    .then((res) => {
      const data = res.data;
      setElemet(data)
      
    })
    .catch(function (error) {

    });
  }

  const showAlert = () =>{

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'bg-green-600 rounded-md text-white p-3 mx-1',
        cancelButton: 'bg-red-600  rounded-md text-white p-3 mx-1'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: 'Estas seguro?',
      text: "No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        var url = ROUTE + MESSAGES + '/' + element.id+'/' + DELETE;
        const config = { headers: { Authorization: "Bearer " + token() } };
        axios
        .post(url, null, config)
        .then((res) => {
          const data = res.data;
          swalWithBootstrapButtons.fire(
            'Eliminado!',
            'El mensaje ha sido eliminado ',
            'success'
          )
        })
        .catch(function (error) {
          swalWithBootstrapButtons.fire(
            'Hubo un error',
            'Hemos tenido problemas intentelo más tarde',
            'success'
          )
        });
        
      }
    })
  }

  return (
    <tr>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='flex items-center'>
          <div className='flex-shrink-0 h-10 w-10'>
            <img
              className='h-10 w-10 rounded-full'
              src={
                element.photo != null
                  ? element.photo
                  : "https://jejuhydrofarms.com/wp-content/uploads/2020/05/blank-profile-picture-973460_1280.png"
              }
              alt=''
            />
          </div>

          <div className='ml-4'>
            <div className='text-sm leading-5 font-medium text-gray-900'>
              <a
                href={"#/message/" + element.id}
                className='text-indigo-600 hover:text-indigo-900'
              >
                {element.title}
              </a>
            </div>
            <div className='text-sm leading-5 text-gray-500'>
              Máximo 30 caracteres
            </div>
          </div>
        </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='text-sm leading-5 text-gray-500'>{element.message}</div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap  border-b border-gray-200'>
        <span
         onClick={active}
          className={
            (element.status
              ? "bg-green-100 text-green-800 "
              : "bg-yellow-400 text-gray-400 ") +
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full cursor-pointer"
          }
        >
          {element.status ? "Activo" : "Inactivo"}
        </span>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        <strong> Inicia</strong> : {element.start_at} <br />
        <strong>Termina</strong> :{element.end_at}
      </td>

      <td className='px-6 py-4 whitespace-no-wrap  text-right border-b border-gray-200 text-sm leading-5 font-medium'>
        <div className="flex">
          <span onClick={showAlert} className="mx-2 cursor-pointer"><TrashIcon className='h-5 w-5 text-red-500 '></TrashIcon> </span>
          <span className="mx-2 cursor-pointer"><PencilAltIcon className='h-5 w-5 text-blue-500'></PencilAltIcon> </span>
        </div>
      </td>
    </tr>
  );
};

export default RowTableMessages;
