import { useState } from "react";
import axios from "axios";

const useRequest = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);

  const fetchData = (url, method, body = null, headers = null) => {
    setloading(true);
    axios[method](url, headers)
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  return { response, error, loading, fetchData };
};

export default useRequest;
