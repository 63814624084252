///LIBRERIES
import { useEffect, useState } from "react";
import axios from "axios";

///HOCKS
import useAuth from "../../Hooks/useAuth";

///COMPONENTES
import Pagination from "../Pagination";
import RowTableAnimation from "../RowTable/RowTableAnimation";

const TableGeneric = ({ isSearch, rowTitles, url, Row, title }) => {
  const [search, setSearch] = useState("");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();
  const token_interno = token();
  const changeUrl = (URL) => {
    console.log(URL);
    setResponse(null);
    URL = URL + "&search=" + search;
    const config = { headers: { Authorization: "Bearer " + token_interno } };
    //HAECER PETICION
    setLoading(true);
    axios
      .get(URL, config)
      .then((res) => {
        setResponse(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setResponse(null);
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const config = { headers: { Authorization: "Bearer " + token_interno } };
      const url_encode = url + "&search=" + search;
      axios
        .get(url_encode, config)
        .then((res) => {
          setResponse(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, url, token_interno]);

  return (
    <>
      <div className='flex  justify-between my-2 w-full'>
        <h1 className='text-3xl font-bold'>{title}</h1>

        {isSearch && (
          <input
            type='text'
            placeholder='Buscador'
            name='search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className='rounded border-gray-400 mb-5 '
          />
        )}
      </div>
      <div className='align-middle inline-block w-min-full md:w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
        <table className='w-min-full md:w-full'>
          <thead>
            <tr>
              {rowTitles &&
                rowTitles.map((datos, index) => {
                  if (datos) {
                    return (
                      <th
                        key={index}
                        className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'
                      >
                        {datos}
                      </th>
                    );
                  }
                  return null;
                })}
            </tr>
          </thead>

          <tbody className='bg-white'>
            {response &&
              response.data.map((datos, index) => {
                if (datos) {
                  return <Row item={datos} key={index} />;
                } else {
                  return <></>;
                }
              })}
            {loading && <RowTableAnimation />}
          </tbody>
        </table>
        <Pagination changeUrl={changeUrl} data={response} />
      </div>
    </>
  );
};

export default TableGeneric;
