import TableGeneric from "../Components/Tables/TableGeneric";
import RowTableMessages from "../Components/RowTable/RowTableMessages";
import { ROUTE, MESSAGES } from "../Constant/Route";

const Messages = () => {
  const url = ROUTE + MESSAGES + "?";
  const rows = ["TÍTULO", "MENSAJE", "ESTADO", "DURACIÓN", "OPCIONES"];
  return (
    <div className='container mx-auto px-6 py-8'>
      <div className='flex justify-between '>
        <h3 className='text-gray-700 text-3xl font-medium'>
          Mensajes de notificación
        </h3>
        <a className='text-white rounded  p-3 bg-indigo-600' href='/#/message'>
          Nueva
        </a>
      </div>

      <div className='mt-4'>
        <div className='flex flex-wrap -mx-6'></div>
      </div>

      <div className='flex flex-col mt-8'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <TableGeneric
            isSearch={true}
            Row={RowTableMessages}
            rowTitles={rows}
            title='Tabla de mensajes'
            url={url}
          />
        </div>
      </div>
    </div>
  );
};

export default Messages;
