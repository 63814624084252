import React from "react";
import BtnProfile from "../Components/BtnMenu";
import ContenNav from "../Components/ContenNav";
import {
  TrendingUpIcon,
  ClipboardCopyIcon,
  AnnotationIcon,
  BellIcon,
} from "@heroicons/react/solid";

const NavBar = ({ changeContent, show, changeShow, sidebar }) => {
  return (
    <div className='flex flex-shrink-0 transition-all fixed h-screen '>
      <div
        onClick={changeShow}
        className={
          (show ? "" : "hidden") +
          " fixed inset-0 z-10 bg-black bg-opacity-50 lg:hidden"
        }
      ></div>
      <div
        className={
          (show ? "" : "hidden ") + " fixed inset-y-0 z-10 w-16 bg-white"
        }
      ></div>

      <nav
        aria-label='Options'
        className='fixed inset-x-0 bottom-0 flex flex-row-reverse items-center justify-between px-4 py-2 bg-white border-t border-indigo-100 sm:hidden shadow-t rounded-t-3xl'
      >
        <button
          onClick={() => changeContent("homeTab")}
          className={
            (show && sidebar === "homeTab"
              ? "text-white bg-indigo-600 "
              : "text-gray-500 bg-white ") +
            "p-2 transition-colors rounded-lg shadow-md hover:bg-indigo-800 hover:text-white focus:outline-none focus:ring focus:ring-indigo-600 focus:ring-offset-white focus:ring-offset-2"
          }
        >
          <TrendingUpIcon className='w-6 h-6' />
        </button>

        <a href='/#'>
          <img
            className='w-10 h-auto'
            src='https://hicoapp.com/static/hicoLogoFull.png'
            alt='HICO DASH'
          />
        </a>

        <BtnProfile />
      </nav>

      <nav
        aria-label='Options'
        className='z-20 flex-col items-center flex-shrink-0 hidden w-16 py-4 bg-white border-r-2 border-indigo-100 shadow-md sm:flex rounded-tr-3xl rounded-br-3xl'
      >
        <div className='flex-shrink-0 py-4'>
          <a href='/#'>
            <img
              className='w-10 h-auto'
              src='https://hicoapp.com/static/hicoLogoFull.png'
              alt='HICO DASH'
            />
          </a>
        </div>
        <div className='flex flex-col items-center flex-1 p-2 space-y-4'>
          <button
            onClick={() => changeContent("homeTab")}
            className={
              (show && sidebar === "homeTab"
                ? "text-white bg-indigo-600"
                : "text-gray-500 bg-white") +
              " p-2 transition-colors rounded-lg shadow-md hover:bg-indigo-800 hover:text-white focus:outline-none focus:ring focus:ring-indigo-600 focus:ring-offset-white focus:ring-offset-2"
            }
          >
            <TrendingUpIcon className='w-6 h-6' />
          </button>

          <button
            onClick={() => changeContent("subsTab")}
            className={
              (show && sidebar === "subsTab"
                ? "text-white bg-indigo-600"
                : "text-gray-500 bg-white") +
              " p-2 transition-colors rounded-lg shadow-md hover:bg-indigo-800 hover:text-white focus:outline-none focus:ring focus:ring-indigo-600 focus:ring-offset-white focus:ring-offset-2"
            }
          >
            <ClipboardCopyIcon className='w-6 h-6' />
          </button>

          <button
            onClick={() => changeContent("mensTab")}
            className={
              (show && sidebar === "mensTab"
                ? "text-white bg-indigo-600 "
                : "text-gray-500 bg-white ") +
              "p-2 transition-colors rounded-lg shadow-md hover:bg-indigo-800 hover:text-white focus:outline-none focus:ring focus:ring-indigo-600 focus:ring-offset-white focus:ring-offset-2"
            }
          >
            <AnnotationIcon className='w-6 h-6' />
          </button>

          <button
            onClick={() => changeContent("notifiTab")}
            className={
              (show && sidebar === "notifiTab"
                ? "text-white bg-indigo-600 "
                : "text-gray-500 bg-white ") +
              "p-2 transition-colors rounded-lg shadow-md hover:bg-indigo-800 hover:text-white focus:outline-none focus:ring focus:ring-indigo-600 focus:ring-offset-white focus:ring-offset-2"
            }
          >
            <BellIcon className='w-6 h-6' />
          </button>
        </div>

        <BtnProfile />
      </nav>

      <ContenNav show={show} sidebar={sidebar} />
    </div>
  );
};

export default NavBar;
