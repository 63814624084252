import React from "react";
import Items from "./Items";

const ContenNav = ({ show, sidebar }) => {
  return (
    <div
      className={
        (show ? "" : "hidden ") +
        " fixed inset-y-0 left-0 z-10 flex-shrink-0 w-64 bg-white border-r-2 border-indigo-100 shadow-lg sm:left-16 rounded-tr-3xl rounded-br-3xl sm:w-72 lg:static lg:w-64"
      }
    >
      <nav className='flex flex-col h-full'>
        <div className='flex items-center justify-center flex-shrink-0 py-10'>
          <a href='/#'>
            <img
              className='w-24 h-auto'
              src='https://hicoapp.com/static/hicoLogoFull.png'
              alt='HICO DASH'
            />
          </a>
        </div>

        <Items sidebar={sidebar} />

        <div className='flex-shrink-0 p-4 mt-10'>
          <div className='hidden p-2 space-y-6 bg-gray-100 rounded-lg md:block'>
            <img
              alt='HICO API'
              className='-mt-10'
              src='https://raw.githubusercontent.com/kamona-ui/dashboard-alpine/52b4b4abb92ef251f6610be416038b48209d7a81/public/assets/images/undraw_web_developer_p3e5.svg'
            />
          </div>
        </div>
      </nav>
    </div>
  );
};
export default ContenNav;
