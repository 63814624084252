import React, { useEffect, useState } from "react";
import {
  UserGroupIcon,
  DocumentReportIcon,
  FolderOpenIcon,
} from "@heroicons/react/solid";
import SquareData from "../Components/SquareData";
import TableDash from "../Components/TableDash";
import useRequest from "../Hooks/useRequest";
import { ROUTE, DASHBOARD } from "../Constant/Route";
import useAuth from "../Hooks/useAuth";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const Home = () => {
  const { response, fetchData } = useRequest();
  const { token } = useAuth();

  const now = new Date();
  const yesterdayBegin = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    now.getDate()
  );
  const todayEnd = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
    999
  );
  const [value, onChange] = useState([yesterdayBegin, todayEnd]);

  useEffect(() => {
    var url =
      ROUTE +
      DASHBOARD +
      "?date_1=" +
      value[0].toISOString().slice(0, 10) +
      "&date_2=" +
      value[1].toISOString().slice(0, 10);
    const config = { headers: { Authorization: "Bearer " + token() } };
    fetchData(url, "get", null, config);
  }, [value]);

  return (
    <div className='container mx-auto px-6 py-8'>
      <div className='flex justify-between '>
        <h3 className='text-gray-700 text-3xl font-medium'>Dashboard</h3>
        <DateRangePicker
          calendarAriaLabel='Toggle calendar'
          clearAriaLabel='Clear value'
          dayAriaLabel='Day'
          monthAriaLabel='Month'
          nativeInputAriaLabel='Date'
          onChange={onChange}
          value={value}
          yearAriaLabel='Year'
        />
      </div>

      <div className='mt-4'>
        <div className='flex flex-wrap -mx-6'>
          {response && (
            <SquareData data={response.doctors} Icon={DocumentReportIcon} />
          )}
          {response && (
            <SquareData data={response.users} Icon={UserGroupIcon} />
          )}
          {response && (
            <SquareData data={response.patients} Icon={FolderOpenIcon} />
          )}
          {response && (
            <SquareData data={response.appointments} Icon={FolderOpenIcon} />
          )}
        </div>
      </div>

      <div className='flex flex-col mt-8'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <TableDash />
        </div>
      </div>
    </div>
  );
};

export default Home;
