import TableSubscriptions from "../Components/TableSubscriptions";

const Subscription = () => {
  return (
    <div className='container mx-auto px-6 py-8'>
      <div className='flex justify-between '>
        <h3 className='text-gray-700 text-3xl font-medium'>Subscripciones</h3>
      </div>

      <div className='mt-4'>
        <div className='flex flex-wrap -mx-6'></div>
      </div>

      <div className='flex flex-col mt-8'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <TableSubscriptions />
        </div>
      </div>
    </div>
  );
};

export default Subscription;
