const RowTableAnimation = () => {
  return (
    <>
      <tr className='animate-pulse bg-gray-100'>
        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 '>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <div className='h-10 w-10 rounded-full bg-gray-300' />
            </div>

            <div className='ml-4 w-full'>
              <div className='text-sm leading-5  py-2 bg-gray-300'></div>
              <div className='text-sm leading-5  my-1 py-2 bg-gray-300'></div>
            </div>
          </div>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 '>
          <div className='text-sm leading-5  py-2 bg-gray-300'></div>
          <div className='text-sm leading-5  my-1 py-2 bg-gray-300'></div>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
          <span className='px-6 inline-flex text-xs leading-5 font-semibold rounded-full py-2 bg-gray-300 '></span>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 '>
          <span className='px-6 inline-flex text-xs leading-5 font-semibold rounded-full py-2 bg-gray-300 '></span>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium'>
          <span className='px-4 inline-flex text-xs leading-5 font-semibold rounded-full py-4 bg-gray-300 '></span>
        </td>
      </tr>
      <tr className='animate-pulse bg-gray-100'>
        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 '>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <div className='h-10 w-10 rounded-full bg-gray-300' />
            </div>

            <div className='ml-4 w-full'>
              <div className='text-sm leading-5  py-2 bg-gray-300'></div>
              <div className='text-sm leading-5  my-1 py-2 bg-gray-300'></div>
            </div>
          </div>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 '>
          <div className='text-sm leading-5  py-2 bg-gray-300'></div>
          <div className='text-sm leading-5  my-1 py-2 bg-gray-300'></div>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
          <span className='px-6 inline-flex text-xs leading-5 font-semibold rounded-full py-2 bg-gray-300 '></span>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 '>
          <span className='px-6 inline-flex text-xs leading-5 font-semibold rounded-full py-2 bg-gray-300 '></span>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium'>
          <span className='px-4 inline-flex text-xs leading-5 font-semibold rounded-full py-4 bg-gray-300 '></span>
        </td>
      </tr>
      <tr className='animate-pulse bg-gray-100'>
        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 '>
          <div className='flex items-center'>
            <div className='flex-shrink-0 h-10 w-10'>
              <div className='h-10 w-10 rounded-full bg-gray-300' />
            </div>

            <div className='ml-4 w-full'>
              <div className='text-sm leading-5  py-2 bg-gray-300'></div>
              <div className='text-sm leading-5  my-1 py-2 bg-gray-300'></div>
            </div>
          </div>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 '>
          <div className='text-sm leading-5  py-2 bg-gray-300'></div>
          <div className='text-sm leading-5  my-1 py-2 bg-gray-300'></div>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
          <span className='px-6 inline-flex text-xs leading-5 font-semibold rounded-full py-2 bg-gray-300 '></span>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 '>
          <span className='px-6 inline-flex text-xs leading-5 font-semibold rounded-full py-2 bg-gray-300 '></span>
        </td>

        <td className='px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium'>
          <span className='px-4 inline-flex text-xs leading-5 font-semibold rounded-full py-4 bg-gray-300 '></span>
        </td>
      </tr>
    </>
  );
};

export default RowTableAnimation;
