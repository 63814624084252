import React from "react";

const ItemNav = ({ icon, ruta, nombre }) => {
  const Icon = icon;
  return (
    <a
      href={ruta}
      className='flex items-center space-x-2 text-indigo-600 transition-colors rounded-lg group hover:bg-indigo-600 hover:text-white'
    >
      <span
        aria-hidden='true'
        className='p-2 transition-colors rounded-lg group-hover:bg-indigo-700 group-hover:text-white'
      >
        <Icon className='h-5 w-5 text-blue-500' />
      </span>
      <span>{nombre}</span>
    </a>
  );
};

export default ItemNav;