import { useEffect, useState } from "react";
import RowTableSubs from "./RowTable/RowTableSubs";
import Pagination from "./Pagination";
import useAuth from "../Hooks/useAuth";
import useRequest from "../Hooks/useRequest";
import { ROUTE, SUBSCRIPTIONS } from "../Constant/Route";
import RowTableAnimation from "./RowTable/RowTableAnimation";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const TableSubscriptions = () => {
  const { response, loading, fetchData } = useRequest();
  const [search, setSearch] = useState("");
  const { token } = useAuth();
  const url = ROUTE + SUBSCRIPTIONS + "?";

  const now = new Date();
  const date_start = new Date(
    now.getFullYear(),
    now.getMonth() - 12,
    now.getDate()
  );
  const date_end = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
    999
  );
  const [value, onChange] = useState([date_start, date_end]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      changeUrl(url);
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [search, url, value]);

  const changeUrl = (URL) => {
    if (value) {
      URL =
        URL +
        "search=" +
        search +
        "&date_1=" +
        value[0].toISOString().slice(0, 10) +
        "&date_2=" +
        value[1].toISOString().slice(0, 10);
    } else {
      URL = URL + "search=" + search;
    }
    const config = { headers: { Authorization: "Bearer " + token() } };
    fetchData(URL, "get", null, config);
  };

  return (
    <>
      <div className='flex justify-between'>
        <input
          type='text'
          placeholder='Buscador'
          name='search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className='w-auto rounded border-gray-400 mb-5 '
        />
        <DateRangePicker
          calendarAriaLabel='Toggle calendar'
          clearAriaLabel='Clear value'
          dayAriaLabel='Day'
          monthAriaLabel='Month'
          nativeInputAriaLabel='Date'
          onChange={onChange}
          value={value}
          yearAriaLabel='Year'
        />
      </div>
      <div className='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
        <table className='min-w-full'>
          <thead>
            <tr>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                Nombre
              </th>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                Expiracion
              </th>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                Estado
              </th>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                Tipo
              </th>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                Fecha pago
              </th>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50'></th>
            </tr>
          </thead>

          <tbody className='bg-white'>
            {response &&
              response.data.map((datos, index) => {
                if (datos) {
                  return <RowTableSubs item={datos} key={index} />;
                } else {
                  return <></>;
                }
              })}
            {loading && <RowTableAnimation />}
          </tbody>
        </table>
        <Pagination changeUrl={changeUrl} data={response} />
      </div>
    </>
  );
};
export default TableSubscriptions;
