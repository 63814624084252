import axios from "axios";
import useAuth from "../Hooks/useAuth";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

/// UTILS
import { ROUTE, USER_DETAILS, TRANSACTIONS, SUBSCRIPTIONS_USER , PAYMENTS_USER, APPOINTMENTS} from "../Constant/Route";
import { estados, getClassState } from "../Utils/Miselaneos";

/// COMPONENTS
import TableGeneric from "../Components/Tables/TableGeneric";
import RowTableSubsUser from "../Components/RowTable/RowTableSubsUser";
import RowTablePayment from "../Components/RowTable/RowTablePayment";
import RowTableAppointmen from "../Components/RowTable/RowTableAppointmen";

const UserDetail = () => {
  const { id } = useParams();
  const { token } = useAuth();
  const [user, setUser] = useState();
  const [table, setTable] = useState("subs");
  
  const url_subs = ROUTE + TRANSACTIONS + "/" + id + "/" + SUBSCRIPTIONS_USER + "?";
  const title_subs = ["TIPO SUBSCRIÓN", "EXPIRACIÓN", "ESTADO", "COSTO", "SRI", "OPCIONES"];

  const url_payment = ROUTE + TRANSACTIONS + "/" + id + "/" + PAYMENTS_USER + "?";
  const title_payment = ["NOMBRE", "TIPO SUBSCRIÓN", "FECHA", "VALOR", "ESTADO", "NÚMERO", "TRANSACCIÓN", "OPCIONES"];
  
  const url_appoinment = ROUTE + USER_DETAILS + "/" + id + "/" + APPOINTMENTS + "?";
  const title_appoinment = ["PACIENTE", "FECHA", "ESTADO", "EDAD", "NOTA"];

  const toke_interno = token();

  const changeTab = (tab) => {
    setTable(tab);
  };

  useEffect(() => {
    if (id) {
      const config = {
        headers: {
          Authorization: "Bearer " + toke_interno,
          "Content-Type": "application/json",
        },
      };

      axios
        .get(ROUTE + USER_DETAILS + "/" + id, config)
        .then((res) => {
          const data = res.data;
          setUser(data);
        })
        .catch(function (error) {});
    }
  }, [toke_interno, id]);

  const schendule = (data) =>
    Object.keys(data).map((day, i) => {
      if (day) {
        return (
          <div key={i} className='text-center rounded-md '>
            <p> {day} </p>
            {user?.user?.doctor?.office?.schedule[day].map((data, index) => {
              return (
                <span
                  className={
                    (data.specialty_id ? "bg-blue-600" : "bg-blue-400") +
                    " px-2 rounded-xl text-sm text-white m-1"
                  }
                  key={index}
                >
                  {data}
                </span>
              );
            })}
          </div>
        );
      }
    });
  return (
    <div className='container mx-auto px-6 py-8'>
      <div className='grid grid-cols-1 md:grid-cols-5'>
        <div className=' col-span-1'>
          <img
            className=' rounded-md md:rounded-full w-1/2 md:w-44 mx-auto mb-2'
            alt=''
            src={user?.user.photo}
          />

          <div className=' col-span-3 text-center font-medium text-lg'>
            {user?.user.name + " " + user?.user.last_name}
          </div>
          <div className=' col-span-3 text-center text-sm'>
            {user?.user.email}
          </div>
          <div className=' col-span-3 text-center text-sm'>
            @{user?.user.uid}
          </div>
        </div>
        <div className='flex flex-col  col-span-1 md:col-span-2'>
          <h1 className='text-2xl font-extrabold text-gray-600'>
            {user?.user.display_name}
          </h1>
          <div className=''>
            <h1 className='text-xl font-medium'> Especialidades</h1>
            {user?.user.doctor.specialty.map((data, index) => {
              return (
                <span
                  className={
                    (data.specialty_id ? "bg-blue-600" : "bg-blue-400") +
                    " px-2 rounded-xl text-sm text-white m-1"
                  }
                  key={index}
                >
                  {data.description}
                </span>
              );
            })}
          </div>
          <div className=''>
            <h1 className='text-xl font-medium'> Horarios de oficina</h1>
            <div className='flex flex-wrap'>
              {user?.user?.doctor?.office?.schedule
                ? schendule(user?.user?.doctor?.office?.schedule)
                : null}
            </div>
          </div>

          <h1 className='text-xl font-medium'>Direccion oficina</h1>
          <p className='text-regular  font-normal'>
            {user?.user?.doctor?.office?.address}
          </p>
        </div>
        <div className='flex flex-col  col-span-1 md:col-span-2'>
          <div className='flex '>
            <h1 className='text-xl font-medium'> Estado</h1>
            <div
              className={
                (user?.user?.doctor?.status
                  ? "bg-green-500"
                  : "bg-yellow-400") + " text-white rounded-lg px-1 m-1"
              }
            >
              {user?.user?.doctor?.status ? "Activo" : "Inactivo"}{" "}
            </div>
          </div>
          <div className='flex '>
            <h1 className='text-xl font-medium'> Citas</h1>
            {user?.data.appointments.map((appointment, ind) => {
              return (
                <div
                  key={ind}
                  className={
                    getClassState(appointment.appointments_state) +
                    "  rounded-lg px-2 py-1 m-1 text-sm"
                  }
                >
                  {estados(appointment.appointments_state) +
                    ": " +
                    appointment.total}{" "}
                </div>
              );
            })}
          </div>
          <div className='flex '>
            <h1 className='text-xl font-medium'> Pacientes:</h1>
            <div className={" text-gray-600 font-medium rounded-lg px-1 m-1"}>
              {user?.data?.patients}
            </div>
          </div>

          <div className='flex flex-col'>
            <h1 className='text-xl font-medium'> Información de factuación:</h1>
            <div className='flex flex-row'>
              <span className=' text-gray-600 font-bold' >Identificacion: </span>
              <div className=' text-gray-600 '> ({user?.data?.billing?.type}) {user?.data?.billing?.identification}</div>
            </div>
            <div className='flex flex-row'>
              <span className=' text-gray-600 font-bold' >Dirección: </span>
              <div className=' text-gray-600 '> {user?.data?.billing?.address}</div>
            </div>
            <div className='flex flex-row'>
              <span className=' text-gray-600 font-bold' >Nombre: </span>
              <div className=' text-gray-600 '> {user?.data?.billing?.name}</div>
            </div>
            <div className='flex flex-row'>
              <span className=' text-gray-600 font-bold' >Email: </span>
              <div className=' text-gray-600 '> {user?.data?.billing?.email}</div>
            </div>
            <div className='flex flex-row'>
              <span className=' text-gray-600 font-bold' >Teléfono: </span>
              <div className=' text-gray-600 '> {user?.data?.billing?.phone}</div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col mt-8'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <div className='flex flex-row gap-1'>
            <div
              onClick={() => changeTab("subs")}
              className={
                (table === "subs" ? "bg-blue-500 " : "bg-green-500 ") +
                "rounded-md  px-2 py-1 text-white cursor-pointer"
              }
            >
              Subscripciones
            </div>
            <div
              onClick={() => changeTab("payment")}
              className={
                (table === "payment" ? "bg-blue-500 " : "bg-green-500 ") +
                "rounded-md  px-2 py-1 text-white cursor-pointer"
              }
            >
              Pagos
            </div>
            <div
              onClick={() => changeTab("appoinment")}
              className={
                (table === "appoinment" ? "bg-blue-500 " : "bg-green-500 ") +
                "rounded-md  px-2 py-1 text-white cursor-pointer"
              }
            >
              Citas
            </div>
          </div>
          {table === "subs" && (
            <TableGeneric
              isSearch={true}
              rowTitles={title_subs}
              Row={RowTableSubsUser}
              url={url_subs}
              title='Tabla de especialidades'
            />
          )}
          {table === "payment" && (
            <TableGeneric
              isSearch={true}
              rowTitles={title_payment}
              Row={RowTablePayment}
              url={url_payment}
              title='Tabla de pagos'
            />
          )}
          {table === "appoinment" && (
            <TableGeneric
              isSearch={true}
              rowTitles={title_appoinment}
              Row={RowTableAppointmen}
              url={url_appoinment}
              title='Tabla de citas'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
