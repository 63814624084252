import RowTypesSubscriptions from "../Components/RowTable/RowTypesSubscriptions";
import TableGeneric from "../Components/Tables/TableGeneric";
import { ROUTE, TYPES_SUBS } from "../Constant/Route";

const TypesSubscriptions = () => {
  const url = ROUTE + TYPES_SUBS + "?";
  const rows = ["CÓDIGO", "INGLES", "ESPAÑOL", "ESTADO","IVA","AMOUNT","MOUNTH", "OPCIONES"];
  return (
    <div className='container mx-auto px-6 py-8'>
      <div className='flex justify-between '>
        <h3 className='text-gray-700 text-3xl font-medium'>Tipos de subscripciones</h3>
        <a className='text-white rounded  p-3 bg-indigo-600' href='/#/type'>
          Nueva
        </a>
      </div>

      <div className='mt-4'>
        <div className='flex flex-wrap -mx-6'></div>
      </div>

      <div className='flex flex-col mt-8'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <TableGeneric
            isSearch={true}
            rowTitles={rows}
            Row={RowTypesSubscriptions}
            url={url}
            title='Tabla de Subscripciones'
          />
        </div>
      </div>
    </div>
  );
};

export default TypesSubscriptions;
