import RowSpecialities from "../Components/RowTable/RowSpecialities";
import TableGeneric from "../Components/Tables/TableGeneric";
import { ROUTE, SPECIALITY } from "../Constant/Route";

const Specialities = () => {
  const url = ROUTE + SPECIALITY + "?";
  const rows = ["DESCRIPCIÓN", "INGLES", "ESPAÑOL", "ESTADO", "OPCIONES"];
  return (
    <div className='container mx-auto px-6 py-8'>
      <div className='flex justify-between '>
        <h3 className='text-gray-700 text-3xl font-medium'>Especialidades</h3>
        <a className='text-white rounded  p-3 bg-indigo-600' href='/#/speciality'>
          Nueva
        </a>
      </div>

      <div className='mt-4'>
        <div className='flex flex-wrap -mx-6'></div>
      </div>

      <div className='flex flex-col mt-8'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <TableGeneric
            isSearch={true}
            rowTitles={rows}
            Row={RowSpecialities}
            url={url}
            title='Tabla de especialidades'
          />
        </div>
      </div>
    </div>
  );
};

export default Specialities;
