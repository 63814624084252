import React, { useState } from "react";

import Header from "../Components/Header";
import Loading from "../Components/Loading";
import NavBar from "../Components/NavBar";

import Home from "../Pages/Home";
import User from "../Pages/User";
import Admin from "../Pages/Admin";
import Messages from "../Pages/Messages";
import UserDetail from "../Pages/UserDetail";
import NewMessage from "../Pages/NewMessage";
import Subscription from "../Pages/Subscription";
import Specialities from "./Specialities";
import Speciality from "./Speciality";
import TypesSubscriptions from "./TypesSubscriptions";
import NewTypeSub from "./NewTypeSub";

import { HashRouter as Router, Switch, Route } from "react-router-dom";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [sidebar, setSidebar] = useState("homeTab");

  const changeShow = () => setShow(!show);
  const changeContent = (contenido) => {
    show && sidebar === contenido ? setShow(false) : setShow(true);
    setSidebar(contenido);
  };

  return (
    <Router>
      <div className='flex h-screen antialiased text-gray-900  dark:bg-dark dark:text-light'>
        <Loading loading={false} />
        <NavBar
          changeShow={changeShow}
          show={show}
          changeContent={changeContent}
          sidebar={sidebar}
        />
        <div className='flex flex-col flex-1'>
          <Header changeContent={changeContent} />
          <div className='flex flex-1'>
            <main className='flex items-center flex-1 md:mx-10 md:px-4 py-8'>
              <Switch>
                <Route exact path='/home' component={Home} />
                <Route exact path='/user' component={User} />
                <Route exact path='/subscriptions' component={Subscription} />
                <Route exact path='/admin/:id' component={Admin} />
                <Route exact path='/admin/' component={Admin} />
                <Route exact path='/user/:id' component={UserDetail} />
                <Route exact path='/messages' component={Messages} />
                <Route exact path='/message' component={NewMessage} />
                <Route exact path='/message/:id' component={NewMessage} />
                <Route exact path='/specialities' component={Specialities} />
                <Route exact path='/speciality' component={Speciality} />
                <Route exact path='/specialities/:id' component={Speciality} />
                <Route exact path='/type' component={NewTypeSub} />
                <Route exact path='/types' component={TypesSubscriptions} />
                <Route exact path='/types/:id' component={NewTypeSub} />
              </Switch>
            </main>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Dashboard;
