const SquareData = ({ data, Icon }) => {
  return (
    <div className='w-full px-6 sm:w-1/3 xl:w-1/4'>
      <div className='flex items-center px-5 py-6 shadow-md rounded-md bg-white'>
        <div className='p-3 rounded-full bg-white bg-opacity-75'>
          <Icon className='h-10 w-10 text-blue-300' />
        </div>

        <div className='mx-5'>
          <h4 className='text-2xl font-semibold text-gray-700'>{data.start}</h4>
          <div className='text-gray-500'>{data.title}</div>
          <div className='text-gray-500 text-xs'>
            Periodo pasado: {data.end}
          </div>
          <div className='text-gray-500 text-xs'>
            Periodo de días ({data.diff})
          </div>
        </div>
      </div>
    </div>
  );
};

export default SquareData;
