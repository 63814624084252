import { getClassState, calcAge, estados } from "../../Utils/Miselaneos";

const RowTableAppointmen = ({ item }) => {
  return (
    <tr>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='flex items-center'>
          <div className='ml-4'>
            <div className='text-sm leading-5 font-medium text-gray-900'>
              {item.name + " " + item.last_name}
            </div>
            <div className='text-sm leading-5 text-gray-500'>{item.email}</div>
          </div>
        </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='text-sm leading-5 text-gray-900'>{item.date}</div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap  border-b border-gray-200'>
        <span
          className={
            getClassState(item.state) +
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full "
          }
        >
          {estados(item.state)}
        </span>
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='text-sm leading-5 text-gray-900'>
          Edad: {calcAge(item.birth_day)}
        </div>
        <div className='text-sm leading-5 text-gray-500'>{item.birth_day} </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {item.notes}
      </td>
    </tr>
  );
};

export default RowTableAppointmen;
