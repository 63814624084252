const RowTableUser = ({ item }) => {
  return (
    <tr>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='flex items-center'>
          <div className='flex-shrink-0 h-10 w-10'>
            <img
              className='h-10 w-10 rounded-full'
              src={
                item.photo != null
                  ? item.photo
                  : "https://jejuhydrofarms.com/wp-content/uploads/2020/05/blank-profile-picture-973460_1280.png"
              }
              alt=''
            />
          </div>

          <div className='ml-4'>
            <div className='text-sm leading-5 font-medium text-gray-900'>
              {item.name + item.last_name}
            </div>
            <div className='text-sm leading-5 text-gray-500'>{item.email}</div>
          </div>
        </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='text-sm leading-5 text-gray-900'>{item.uid}</div>
        <div className='text-sm leading-5 text-gray-500'>
          Identidicador único
        </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap  border-b border-gray-200'>
        <span
          className={
            (item.status
              ? "bg-green-100 text-green-800 "
              : "bg-yellow-400 text-gray-400 ") +
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full "
          }
        >
          {item.status ? "Activo" : "Inactivo"}
        </span>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {item.type}
      </td>

      <td className='px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium'>
        <a
          href={"#/user/" + item.id}
          className='text-indigo-600 hover:text-indigo-900'
        >
          Ver
        </a>
      </td>
    </tr>
  );
};

export default RowTableUser;
