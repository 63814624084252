import axios from "axios";
import { useForm } from "react-hook-form";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ROUTE, NOTIFICAR } from "../../Constant/Route";

import useAuth from "../../Hooks/useAuth";

export default function MailNotification({ isOpen, setIsOpen, item }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [mails, setMails] = useState([]);
  const { token } = useAuth();

  function closeModal() {
    setIsOpen(false);
  }

  const onSubmit = (form) => {
    var arr = mails;
    if (!mails.includes(form.email)) {
      arr.push(form.email);
      setMails(arr);
    }
    setValue("email", "");
  };
  const sendMails = () => {
    // setLoading(true);
    var url = ROUTE + "transactions/" + item.id + "/" + NOTIFICAR;
    const config = { headers: { Authorization: "Bearer " + token() } };
    console.log(mails);
    axios
      .post(url, { emails: mails }, config)
      .then((res) => {
       
        setMails([]);
        // setElement(data.data);
        //setLoading(false);
        closeModal();
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
        } else if (error.request) {
          // The request was made but no response was received
          //console.log(error.request);
        } else {
          // console.log("Error", error.message);
        }
      });
  };
  function removeItemFromArr(item) {
    var arr = mails;
    arr = arr.filter(function (e) {
      return e !== item;
    });
    setMails(arr);
  }
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto bg-gray-900 bg-opacity-70'
          onClose={closeModal}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0' />
            </Transition.Child>

            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900'
                >
                  Ingrese los correos a los que desea notificar
                </Dialog.Title>
                <div className='mt-2'>
                  <div className='flex flex-wrap'>
                    {mails?.map((data, index) => {
                      return (
                        <span
                          className={
                            "bg-blue-600 px-2 rounded-xl text-sm text-white m-1 h-5 w-auto"
                          }
                          key={index}
                        >
                          {data}
                          <span
                            onClick={() => removeItemFromArr(data)}
                            className='rounded-full bg-red-500 text-white text-center  ml-1  px-2 cursor-pointer'
                          >
                            x
                          </span>
                        </span>
                      );
                    })}
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mt-1 flex rounded-md shadow-sm'>
                      <input
                        type='email'
                        placeholder='user@dintinamo.com'
                        {...register("email", { required: true })}
                        className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md  boder-r-0 sm:text-sm border-gray-300'
                      />
                      <button className='inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
                        add
                      </button>
                    </div>
                    {errors.email && (
                      <div className='text-sm  text-red-400 tracking-wide font-thin'>
                        Campo obligatorio
                      </div>
                    )}
                  </form>
                </div>

                <div className='mt-4'>
                  <button
                    type='button'
                    className='inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500'
                    onClick={sendMails}
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
