import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { ROUTE, USER, USER_CREATE } from "../Constant/Route";
import useAuth from "../Hooks/useAuth";

const Admin = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const { token } = useAuth();
  const history = useHistory();

  const {
    register,
    //formState: {  },
    handleSubmit,
    setValue,
  } = useForm();

  const config = { headers: { Authorization: "Bearer " + token() } };

  useEffect(() => {
    if (id) {
      axios
        .get(ROUTE + USER + "/" + id, config)
        .then((res) => {
          const data = res.data;
          setUser(data);
          setValue("name", data.name);
          setValue("last_name", data.last_name);
          setValue("email", data.email);
          setValue("type", data.type);
          setValue("gender", data.gender);
          setValue("status", data.status);
          // setDefaultValue(data)
        })
        .catch(function (error) {});
    }
  }, []);

  const onSubmit = (data) => {
    var url = null;
    if (user) {
      url = ROUTE + USER + "/" + user.id;
    } else {
      url = ROUTE + USER_CREATE;
    }

    axios
      .post(url, data, config)
      .then((res) => {
        // const data = res.data;
        history.push("/home");
      })
      .catch(function (error) {
        if (error.response) {
          
        } else if (error.request) {
        } else {
        }
      });
  };
  return (
    <div className='container mx-auto px-6 py-8'>
      <div className='flex justify-between '>
        <h3 className='text-gray-700 text-3xl font-medium'></h3>
      </div>

      <div className='mt-4'>
        <div className='flex flex-wrap -mx-6'> </div>
      </div>

      <div className='flex flex-col mt-8'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <div className='mt-10 sm:mt-0'>
            <div className='md:grid  md:gap-6'>
              <div className=''>
                <div className='px-4 sm:px-0'>
                  <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    {user ? "Información de  " + user.name : "Nuevo usuario"}
                  </h3>
                  <p className='mt-1 text-sm text-gray-600'>
                    Recuerde que la dirección de correo no se puede editar.
                  </p>
                </div>
              </div>
              <div className='mt-5 md:mt-0 '>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='shadow overflow-hidden sm:rounded-md'>
                    <div className='px-4 py-5 bg-white sm:p-6'>
                      <div className='grid grid-cols-6 gap-6'>
                        <div className='col-span-6 sm:col-span-3'>
                          <label className='block text-sm font-medium text-gray-700'>
                            Nombre
                          </label>
                          <input
                            type='text'
                            name='name'
                            {...register("name", { required: true })}
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                          <label className='block text-sm font-medium text-gray-700'>
                            Apellido
                          </label>
                          <input
                            type='text'
                            {...register("last_name", { required: true })}
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                          <label className='block text-sm font-medium text-gray-700'>
                            Email
                          </label>
                          <input
                            type='email'
                            {...register("email", { required: true })}
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>

                        <div className='col-span-6 sm:col-span-3'>
                          <label className='block text-sm font-medium text-gray-700'>
                            Tipo usuario
                          </label>
                          <select
                            name='type'
                            {...register("type")}
                            className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                          >
                            <option value='8'>Admin </option>
                          </select>
                        </div>

                        <div className='col-span-6 sm:col-span-3 '>
                          <label className='block text-sm font-medium text-gray-700'>
                            Genero
                          </label>
                          <select
                            {...register("gender", { required: true })}
                            className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                          >
                            <option value='1'>Masculino</option>
                            <option value='2'>Femenino</option>
                          </select>
                        </div>

                        <div
                          className={
                            (user ? "hidden " : "") + "col-span-6 sm:col-span-3"
                          }
                        >
                          <label className='block text-sm font-medium text-gray-700'>
                            Contraseña
                          </label>
                          <input
                            type='password'
                            {...register("password", {
                              required: user ? false : true,
                            })}
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3 '>
                          <div className='flex items-center '>
                            <input
                              {...register("status", { required: false })}
                              type='checkbox'
                              className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                            />
                            <div className='ml-3 text-sm'>
                              <label
                                htmlFor='comments'
                                className='font-medium text-gray-700'
                              >
                                Activo
                              </label>
                              <p className='text-gray-500'>
                                El usuario puede ser utilizado
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='px-4 py-3  text-right sm:px-6'>
                      <button
                        type='submit'
                        className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
