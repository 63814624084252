import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { ROUTE, MESSAGES } from "../Constant/Route";
import useAuth from "../Hooks/useAuth";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";


const NewMessage = () => {
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const { token } = useAuth();
  const history = useHistory();
  const now = new Date();

  const yesterdayBegin = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    now.getDate()
  );
  const todayEnd = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
    999
  );
  const [value, onChange] = useState([yesterdayBegin, todayEnd]);

  const {
    register,
    //formState: {  },
    handleSubmit,
    setValue,
  } = useForm();

  const config = { headers: { Authorization: "Bearer " + token() } };

  useEffect(() => {
    if (id) {
      axios
        .get(ROUTE + MESSAGES + "/" + id, config)
        .then((res) => {
          const data = res.data;
          setMessage(data)
          setValue("title", data.title);
          setValue("message", data.message);
        //   setValue("type", data.type);
        //   setValue("gender", data.gender);
          setValue("status", data.status);
          // setDefaultValue(data)
        })
        .catch(function (error) {});
    }
  }, []);

  const onSubmit = (data) => {
    var url = null;
    if (message) {
      url = ROUTE + MESSAGES + "/" + id;
    } else {
      url = ROUTE + MESSAGES;
    }
    console.log(url);

    data.start_at =  value[0].toISOString().slice(0, 19)
    data.end_at =  value[0].toISOString().slice(0, 19)
    console.log(data);

    axios
      .post(url, data, config)
      .then((res) => {
         const data = res.data;
         console.log(data);
        history.push("/#/message");
      })
      .catch(function (error) {
      });
  };
  return (
    <div className='container mx-auto px-6 py-8'>
      <div className='flex justify-between '>
        <h3 className='text-gray-700 text-3xl font-medium'></h3>
      </div>

      <div className='mt-4'>
        <div className='flex flex-wrap -mx-6'> </div>
      </div>

      <div className='flex flex-col mt-8'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <div className='mt-10 sm:mt-0'>
            <div className='md:grid  md:gap-6'>
              <div className=''>
                <div className='px-4 sm:px-0'>
                  <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    {message
                      ? "Información de  " + message.title
                      : "Nuevo usuario"}
                  </h3>
                  <p className='mt-1 text-sm text-gray-600'>
                    Recuerde que la dirección de correo no se puede editar.
                  </p>
                </div>
              </div>
              <div className='mt-5 md:mt-0 '>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='shadow overflow-hidden sm:rounded-md'>
                    <div className='px-4 py-5 bg-white sm:p-6'>
                      <div className='grid grid-cols-6 gap-6'>
                        <div className='col-span-6 sm:col-span-3'>
                          <label className='block text-sm font-medium text-gray-700'>
                            Nombre
                          </label>
                          <input
                            type='text'
                            {...register("title", { required: true })}
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3'> </div>

                        <div className='col-span-6 sm:col-span-3'>
                          <label className='block text-sm font-medium text-gray-700'>
                          message
                          </label>
                          <input
                            type='text'
                            {...register("message", { required: true })}
                            className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                          />
                        </div>
                       
                        <div className='col-span-6 sm:col-span-3'> </div>
                        <div className='col-span-6 sm:col-span-3 '>
                          <div className='flex items-center '>
                            <input
                              {...register("status", { required: false })}
                              type='checkbox'
                              className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
                            />
                            <div className='ml-3 text-sm'>
                              <label
                                htmlFor='comments'
                                className='font-medium text-gray-700'
                              >
                                Activo
                              </label>
                              <p className='text-gray-500'>
                                El usuario puede ser utilizado
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='col-span-6 sm:col-span-3'> </div>

<div className='col-span-6 sm:col-span-3'>
  <label className='block text-sm font-medium text-gray-700'>
    Inicio - Fin
  </label>
  <DateRangePicker
    calendarAriaLabel='Toggle calendar'
    clearAriaLabel='Clear value'
    dayAriaLabel='Day'
    monthAriaLabel='Month'
    nativeInputAriaLabel='Date'
    onChange={onChange}
    value={value}
    yearAriaLabel='Year'
  />
</div>
                      </div>
                    </div>
                    <div className='px-4 py-3  text-right sm:px-6'>
                      <button
                        type='submit'
                        className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMessage;
