import axios from "axios";
import { useState } from "react";
import {  PencilAltIcon } from "@heroicons/react/solid";

import { ROUTE, TYPES_SUBS, ACTIVE } from "../../Constant/Route";
import useAuth from "../../Hooks/useAuth";

const RowTypesSubscriptions = ({ item }) => {
  const [element, setElemet] = useState(item);
  const { token } = useAuth();

  const active = () => {
    var url = ROUTE + TYPES_SUBS + "/" + element.id + "/" + ACTIVE;
    const config = { headers: { Authorization: "Bearer " + token() } };
    const body = { status: !element.status };
    axios
      .post(url, body, config)
      .then((res) => {
        const data = res.data;
        setElemet(data);
      })
      .catch(function (error) {});
  };


  return (
    <tr>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='flex items-center'>
          <div className='ml-4'>
            <div className='text-sm leading-5 font-medium text-gray-900'>
              <a
                href={"#/types/" + element.id}
                className='text-indigo-600 hover:text-indigo-900'
              >
                {element.type}
              </a>
            </div>
            <div className='text-sm leading-5 text-gray-500'>
              Nombre interno
            </div>
          </div>
        </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='text-sm leading-5 text-gray-500'>
          {element.label_en}
        </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {element.label_es}
      </td>

      <td className='px-6 py-4 whitespace-no-wrap  border-b border-gray-200'>
        <span
          onClick={active}
          className={
            (element.status
              ? "bg-green-100 text-green-800 "
              : "bg-yellow-400 text-gray-400 ") +
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full cursor-pointer"
          }
        >
          {element.status ? "Activo" : "Inactivo"}
        </span>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {element.iva}
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {element.amount}
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {element.month}
      </td>

      <td className='px-6 py-4 whitespace-no-wrap  text-right border-b border-gray-200 text-sm leading-5 font-medium'>
        <div className='flex'>
          <span className='mx-2 cursor-pointer'>
            <a href={"#/types/" + element.id}>
              <PencilAltIcon className='h-5 w-5 text-blue-500'></PencilAltIcon>{" "}
            </a>
          </span>
        </div>
      </td>
    </tr>
  );
};

export default RowTypesSubscriptions;
