import React from "react";

const Loading = ({ loading }) => {
  return (
    <div
      className={
        (loading ? "" : "hidden") +
        "  fixed inset-0 z-50  items-center justify-center text-2xl font-semibold text-white bg-indigo-800"
      }
    >
      Loading.....
    </div>
  );
};

export default Loading;
