import { useState } from "react";

const useAuth = () => {
  const [data, setValue] = useState(JSON.parse(localStorage.getItem("user")));

  const setData = (value) => {
    value = JSON.stringify(value);
    localStorage.setItem("user", value);
    setValue(JSON.parse(value));
  };

  const token = () => {
    try {
      return data.access_token;
    } catch (error) {
      return null;
    }
  };
  const user = () => {
    try {
      return data.user;
    } catch (error) {
      return null;
    }
  };

  return { data, setData, token, user };
};

export default useAuth;
