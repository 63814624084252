import React from "react";
import { Redirect, Route } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

const Verify = () => {
  const { data } = useAuth();
  return (
    <Route>
      {data === null ? <Redirect to='/login' /> : <Redirect to='/home' />}
    </Route>
  );
};
export default Verify;
