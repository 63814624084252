export const isProduction = false;

export const ROUTE = isProduction
  ? "https://api.hicoapp.com/api/"
  : "https://dev.hicoapp.com/api/";
 //  :"http://127.0.0.1:8000/api/"; 
export const LOGIN = "admin/login";
export const USER = "admin/user";
export const USER_CREATE = "admin/create";
export const DASHBOARD = "admin/dashboard";
export const SUBSCRIPTIONS = "admin/subscriptions";
export const USER_DETAILS = "admin/user/detail";
export const APPOINTMENTS = "appointment";
export const TRANSACTIONS = "transactions";
export const SUBSCRIPTIONS_USER = "subscriptions";
export const PAYMENTS_USER = "payments";
export const FACTURAR = "facturar";
export const REFOUND = "payment/refound";
export const NOTIFICAR = "notificar";
export const MESSAGES = "admin/messages";
export const SPECIALITY = "admin/specialities";
export const TYPES_SUBS = "admin/sub-types";
export const DELETE  = "delete";
export const ACTIVE = "active";
export const TYPE = "type";
