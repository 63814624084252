import { useState } from "react";
import { ROUTE, FACTURAR } from "../../Constant/Route";
import useAuth from "../../Hooks/useAuth";
import MailNotification from "../Dialog/MailNotification";
import Swal from "sweetalert2";

import axios from "axios";

const RowTableSubsUser = ({ item }) => {
  const [element, setElement] = useState(item);
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "bg-green-600 rounded-md text-white p-3 mx-1",
      cancelButton: "bg-red-600  rounded-md text-white p-3 mx-1",
    },
    buttonsStyling: false,
  });
  const load = () => {
    setLoading(true);
    var url = ROUTE + "transactions/" + item.id + "/" + FACTURAR;
    const config = { headers: { Authorization: "Bearer " + token() } };
    axios
      .post(url, {}, config)
      .then((res) => {
        const data = res.data;
        data.data.approved = data.data.sri;
        console.log(data);
        setElement(data.data);
        setLoading(false);
        if (data.data.sri === 0) {
          swalWithBootstrapButtons.fire(
            "Error al comunicarse!",
            "Hubo un error al comunicarse con el SRI ",
            "error"
          );
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          // Request made and server responded
          swalWithBootstrapButtons.fire(
            "Error al comunicarse!",
            "Hubo un error al comunicarse con el SRI ",
            "error"
          );
        } else if (error.request) {
          // The request was made but no response was received
          //console.log(error.request);
          swalWithBootstrapButtons.fire(
            "Error al comunicarse!",
            "Hubo un error al comunicarse con el SRI ",
            "error"
          );
        } else {
          // console.log("Error", error.message);
          swalWithBootstrapButtons.fire(
            "Error al comunicarse!",
            "Hubo un error al comunicarse con el SRI ",
            "error"
          );
        }
      });
  };
  return (
    <tr>
      <MailNotification setIsOpen={setShow} isOpen={show} item={item} />
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='flex items-center'>
          <div className='ml-4'>
            <div className='text-sm leading-5 font-medium text-gray-900'>
              {item.label_es}
            </div>
            <div className='text-sm leading-5 text-gray-500'></div>
          </div>
        </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='text-sm leading-5 text-gray-900'>{item.expires_at}</div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap  border-b border-gray-200'>
        <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full '>
          {element.state}
        </span>
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='text-sm leading-5 text-gray-900'>
          ${item.amount + item.iva}
        </div>
        <div className='text-sm leading-5 text-gray-500'>incluye IVA</div>
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        <div className='text-sm leading-5 text-gray-900'>
          {element.approved === 0 || element.approved === false
            ? "NO ENVIADO"
            : element.state}
        </div>
        <div className='text-sm leading-5 text-gray-500'>
          {element.approved === 0 || element.approved === false
            ? ""
            : element.establecimiento +
              "-" +
              element.punto_emision +
              "-" +
              element.secuencial}
        </div>
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {loading ? (
          <button
            type='button'
            className='bg-red-600 rounded-md text-white font-semibold flex flex-row px-2 py-1 items-center'
            disabled
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-3 w-3 animate-spin mx-1'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
              />
            </svg>
            Procesando
          </button>
        ) : element.approved === 0 ? (
          item.state === "ANULADO" ? (
            <button
              className='bg-gray-500 rounded-md  px-3 py-1 text-white m-1 cursor-not-allowed '
              disabled
              type='button'
            >
              NO APLICA
            </button>
          ) : (
            <button
              className='bg-green-500 rounded-md  px-3 py-1 text-white m-1 hover:bg-green-700 '
              onClick={load}
              type='button'
            >
              Enviar factura
            </button>
          )
        ) : item.state === "ANULADO" ? (
          <button
            className='bg-gray-500 rounded-md  px-3 py-1 text-white m-1 cursor-not-allowed '
            disabled
            type='button'
          >
            NO APLICA
          </button>
        ) : (
          <button
            className='bg-green-500 rounded-md  px-3 py-1 text-white m-1  '
            type='button'
            onClick={() => setShow(true)}
          >
            Notificar a
          </button>
        )}
      </td>
    </tr>
  );
};

export default RowTableSubsUser;
