import { useEffect, useState } from "react";
import RowTable from "./RowTable/RowTable";
import Pagination from "./Pagination";
import useAuth from "../Hooks/useAuth";
import useRequest from "../Hooks/useRequest";
import { ROUTE, USER } from "../Constant/Route";
import RowTableAnimation from "./RowTable/RowTableAnimation";

const TableDash = () => {
  const { response, loading, fetchData } = useRequest();
  const [search, setSearch] = useState("");
  const { token } = useAuth();
  const url = ROUTE + USER + "?isAdmin=1";

  const changeUrl = (URL) => {
    URL = URL + "&search=" + search;
    const config = { headers: { Authorization: "Bearer " + token() } };
    fetchData(URL, "get", null, config);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      changeUrl(url);
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [search, url]);

  return (
    <>
      <div className='flex justify-end'>
        <input
          type='text'
          placeholder='Buscador'
          name='search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className='w-auto rounded border-gray-400 mb-5 '
        />
      </div>
      <div className='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
        <table className='min-w-full'>
          <thead>
            <tr>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                Nombre
              </th>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                Especialidad
              </th>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                Estado
              </th>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                Tipo usuario
              </th>
              <th className='px-6 py-3 border-b border-gray-200 bg-gray-50'></th>
            </tr>
          </thead>

          <tbody className='bg-white'>
            {response &&
              response.data.map((datos, index) => {
                if (datos) {
                  return <RowTable item={datos} key={index} />;
                } else {
                  return <></>;
                }
              })}
            {loading && <RowTableAnimation />}
          </tbody>
        </table>
        <Pagination changeUrl={changeUrl} data={response} />
      </div>
    </>
  );
};
export default TableDash;
