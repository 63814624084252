const RowTableSubs = ({ item }) => {
  return (
    <tr>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='flex items-center'>
          <div className='ml-4'>
            <div className='text-sm leading-5 font-medium text-gray-900'>
              {item.name + " " + item.last_name}
            </div>
            <div className='text-sm leading-5 text-gray-500'>{item.email}</div>
          </div>
        </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='text-sm leading-5 text-gray-500'>{item.expires_at}</div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap  border-b border-gray-200'>
        <span
          className={
            (item.active ? "bg-green-400 " : "bg-red-400 ") +
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full text-gray-300"
          }
        >
          {item.active ? "Activo" : "Desactivado"}
        </span>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {item.type}
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {item.paid_at}
      </td>

      <td className='px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium'>
        <a
          href={"/admin/subscriptions/" + item.id}
          className='text-indigo-600 hover:text-indigo-900'
        >
          Ver
        </a>
      </td>
    </tr>
  );
};

export default RowTableSubs;
