import axios from "axios";
import { useState } from "react";
import Swal from 'sweetalert2'

import { ROUTE, REFOUND } from "../../Constant/Route";
import useAuth from "../../Hooks/useAuth";

const RowTablePayment = ({ item }) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [element, setElement] = useState(item);

  const refount = () => {
    setLoading(true);
    var url = ROUTE + REFOUND;
    const config = { headers: { Authorization: "Bearer " + token() } };
    const body = { transaction_id: item.transaction_reference };
    axios
      .post(url, body, config)
      .then((res) => {
        const data = res.data;
        var temp = element;
        if (data.data.status === "success") {
          temp.state = "REFOUND";
        }
        setElement(temp);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          // Request made and server responded
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          console.log("Error", error.message);
        }
      });
  };
  const showAlert = () =>{

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'bg-green-600 rounded-md text-white p-3 mx-1',
        cancelButton: 'bg-red-600  rounded-md text-white p-3 mx-1'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: 'Estas seguro?',
      text: "No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, reversar!',
      cancelButtonText: 'No, cancelar!',
      reverseButtons: true
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        refount();
      }
    })
  }
  return (
    <tr>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='flex items-center'>
          <div className='ml-4'>
            <div className='text-sm leading-5 font-medium text-gray-900'>
              {item.name + " " + item.last_name}
            </div>
            <div className='text-sm leading-5 text-gray-500'></div>
          </div>
        </div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 '>
        <div className='text-sm leading-5 text-gray-900'>{item.label_es}</div>
      </td>

      <td className='px-6 py-4 whitespace-no-wrap  border-b border-gray-200'>
        <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full '>
          {item.date_time}
        </span>
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
        <div className='text-sm leading-5 text-gray-900'>
          ${item.amount + item.iva}
        </div>
        <div className='text-sm leading-5 text-gray-500'>incluye IVA</div>
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {element.state}
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {item.number}
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {item.transaction_reference}
      </td>
      <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500 '>
        {loading ? (
          <button
            type='button'
            className='bg-red-600 rounded-md text-white font-semibold flex flex-row px-2 py-1 items-center'
            disabled
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-3 w-3 animate-spin mx-1'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
              />
            </svg>
            Procesando
          </button>
        ) : element.state !== "ACCEPTED" ? (
          <button
            className='bg-gray-500 rounded-md px-3 py-1 text-white cursor-not-allowed'
            disabled
          >
            {element.state}
          </button>
        ) : (
          <button
            className='bg-red-500 rounded-md px-3 py-1 text-white'
            onClick={showAlert}
            type='button'
          >
            Reversar
          </button>
        )}
      </td>
    </tr>
  );
};

export default RowTablePayment;
