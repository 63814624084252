import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

export default function Pagination({ changeUrl, data }) {
  if (data === null) {
    return <div></div>;
  }
  return (
    <div className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
      <div className='flex-1 flex justify-between sm:hidden'>
        {data && (
          <span
            onClick={() => changeUrl(data.links[0].url)}
            className='relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mouse-pointer'
          >
            Previous
          </span>
        )}
        {data && (
          <span
            onClick={() => changeUrl(data.links[data.links.length - 1].url)}
            className='ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 '
          >
            Next
          </span>
        )}
      </div>
      <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
        <div>
          <p className='text-sm text-gray-700'>
            Showing <span className='font-medium'>{data.from}</span> to{" "}
            <span className='font-medium'>{data.to}</span> of{" "}
            <span className='font-medium'>{data.total}</span> results
          </p>
        </div>
        <div>
          <nav
            className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
            aria-label='Pagination'
          >
            {data.links.map((items, index) => {
              if (items) {
                if (data.links.length - 1 === index) {
                  return (
                    <span
                      key={index}
                      onClick={() => changeUrl(items.url)}
                      className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer'
                    >
                      <span className='sr-only'>Next</span>
                      <ChevronRightIcon
                        className='h-5 w-5'
                        aria-hidden='true'
                      />
                    </span>
                  );
                } else if (index === 0) {
                  return (
                    <span
                      onClick={() => changeUrl(items.url)}
                      key={index}
                      className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer'
                    >
                      <span className='sr-only'>Previous</span>
                      <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                    </span>
                  );
                } else {
                  return (
                    <span
                      key={index}
                      onClick={() => changeUrl(items.url)}
                      className={
                        items.active
                          ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
                          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
                      }
                    >
                      {data.links.length - 1 === index ? "Next" : items.label}
                    </span>
                  );
                }
              }
              return <></>;
            })}
          </nav>
        </div>
      </div>
    </div>
  );
}
