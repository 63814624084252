import React, { useState } from "react";
import {
  AdjustmentsIcon,
  ClipboardCopyIcon,
  AnnotationIcon,
  BellIcon,
} from "@heroicons/react/solid";

const Header = ({ changeContent }) => {
  const [subheader, setSubheader] = useState(false);
  const changeHeader = () => setSubheader(!subheader);

  return (
    <header className='relative flex items-center justify-between flex-shrink-0 p-4'>
      <div className='flex-1'></div>
      <div className='items-center hidden ml-4 sm:flex'></div>

      <button
        onClick={changeHeader}
        className='p-2 text-gray-300 bg-white rounded-lg shadow-md sm:hidden hover:text-white hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-white focus:ring-offset-gray-100 focus:ring-offset-4'
      >
        <AdjustmentsIcon className='w-6 h-6' />
      </button>

      <div
        className={
          (subheader ? "" : "hidden ") +
          " absolute flex items-center justify-between p-2 bg-white rounded-md shadow-lg sm:hidden top-16 left-5 right-5"
        }
      >
        <button
          onClick={() => changeContent("subsTab")}
          className='p-2 text-gray-400 bg-white rounded-lg shadow-md hover:text-gray-600 focus:outline-none focus:ring focus:ring-white focus:ring-offset-gray-100 focus:ring-offset-4'
        >
          <ClipboardCopyIcon className='w-6 h-6' />
        </button>
        <button
          onClick={() => changeContent("mensTab")}
          className='p-2 text-gray-400 bg-white rounded-lg shadow-md hover:text-gray-600 focus:outline-none focus:ring focus:ring-white focus:ring-offset-gray-100 focus:ring-offset-4'
        >
          <AnnotationIcon className='w-6 h-6' />
        </button>
        <button
          onClick={() => changeContent("notifiTab")}
          className='p-2 text-gray-400 bg-white rounded-lg shadow-md hover:text-gray-600 focus:outline-none focus:ring focus:ring-white focus:ring-offset-gray-100 focus:ring-offset-4'
        >
          <BellIcon className='w-6 h-6' />
        </button>
      </div>
    </header>
  );
};

export default Header;
