import React  from 'react';
import './App.css';
import Dashboard from './Pages/Dashboard';
import Verify from './Pages/Verify';
import Login from './Pages/Login';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

/* HACER UN HOOCK PARA COMPROBAR SI EL USUARIO ESTA LOGEADO*/
const App = () => {
  return (
      <Router>
        <Switch>
          <Route exact path='/' component={Verify} />
          <Route exact path='/login' component={Login} />
          <Route path='*' component={Dashboard} />
        </Switch>
      </Router>
  );
};

export default App;