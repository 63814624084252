export const getClassState = states => {
    var  estado = null ; 
     switch (states) {
       case 'C':
         estado = 'bg-red-400 text-white '
         break;
       case 'S':
         estado = 'bg-yellow-400 text-gray-400 '
         break;
       case 'F':
         estado = 'bg-green-100 text-green-800 '
         break;
       default:
         estado = 'bg-gray-300 text-white'
         break;
     }
     return estado
  }

  export const calcAge = dateString => {
    var birthday = +new Date(dateString);
    return ~~((Date.now() - birthday) / (31557600000));
  }
 
  export  const estados = st => {
    var  estado = null ; 
     switch (st) {
       case 'C':
         estado = 'Canceldo'
         break;
       case 'S':
         estado = 'Agendado'
         break;
       case 'F':
         estado = 'Terminado'
         break;
       case 'D':
         estado = 'Atendiendo'
         break;
       default:
         estado = 'Otro :' + st
         break;
     }
     return estado
   }