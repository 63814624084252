import React, { useState } from "react";

const BtnProfile = () => {
  const [profile, setProfile] = useState(false);

  const changeProfile = () => setProfile(!profile);
  const close = () => setProfile(false);

  return (
    <div
      className='relative flex items-center flex-shrink-0 p-2'
      onBlur={close}
    >
      <button
        onClick={changeProfile}
        className='transition-opacity rounded-lg opacity-80 hover:opacity-100 focus:outline-none focus:ring focus:ring-indigo-600 focus:ring-offset-white focus:ring-offset-2'
      >
        <img
          className='w-10 h-10 rounded-lg shadow-md bg-indigo-700 p-1'
          src='https://hicoapp.com/static/hicoHWhite.png'
          alt='H'
        />
        <span className='sr-only'>User menu</span>
      </button>
      <div
        tabIndex='-1'
        className={
          (profile ? "" : "hidden") +
          " absolute w-48 py-1 mt-2 origin-bottom-left bg-white rounded-md shadow-lg left-10 bottom-14 focus:outline-none"
        }
        role='menu'
        aria-orientation='vertical'
        aria-label='user menu'
      >
        <a
          href='/#'
          className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
          role='menuitem'
        >
          Your Profile
        </a>

        <a
          href='/#'
          className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
          role='menuitem'
        >
          Settings
        </a>

        <a
          href='/#'
          className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
          role='menuitem'
        >
          Sign out
        </a>
      </div>
    </div>
  );
};
export default BtnProfile;
