import TableGeneric from "../Components/Tables/TableGeneric";
import RowTableUser from "../Components/RowTable/RowTableUser";
import { ROUTE, USER } from "../Constant/Route";

const User = () => {
  const url = ROUTE + USER + "?type=1&";
  const rows = ["NOMBRE", "ESPECIALIDAD", "ESTADO", "TIPO USUARIO", "OPCIONES"];

  return (
    <div className='container mx-auto px-6 py-8'>
      <div className='flex justify-between '>
        <h3 className='text-gray-700 text-3xl font-medium'>User</h3>
      </div>

      <div className='mt-4'>
        <div className='flex flex-wrap -mx-6'></div>
      </div>

      <div className='flex flex-col mt-8'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <TableGeneric
            isSearch={true}
            rowTitles={rows}
            Row={RowTableUser}
            url={url}
            title='Tabla de especialidades'
          />
        </div>
      </div>
    </div>
  );
};

export default User;
