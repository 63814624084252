import React from "react";
import ItemNav from "./ItemNav";
import {
  UserGroupIcon,
  HomeIcon,
  ClipboardListIcon,
  ChartBarIcon,
  ChatIcon,
  BellIcon,
  AcademicCapIcon
} from "@heroicons/react/solid";

const Items = ({ sidebar }) => {
  let contenido = [
    {
      nombre: "homeTab",
      elements: [
        { ruta: "/#/home", nombre: "Dashboard", icon: ChartBarIcon },
        { ruta: "/#/user", nombre: "User", icon: UserGroupIcon },
        { ruta: "/#", nombre: "Citas", icon: ClipboardListIcon },
      ],
    },
    {
      nombre: "subsTab",
      elements: [
        {
          ruta: "/#/specialities",
          nombre: "Especialidades",
          icon: AcademicCapIcon,
        },
        {
          ruta: "/#/transactions",
          nombre: "Trasacciones",
          icon: UserGroupIcon,
        },
        {
          ruta: "/#/types",
          nombre: "Tipos subscripciones",
          icon: UserGroupIcon,
        },
        {
          ruta: "/#/subscriptions",
          nombre: "Subscripciones",
          icon: ClipboardListIcon,
        },
      ],
    },
    {
      nombre: "notifiTab",
      elements: [
        { ruta: "/#", nombre: "Home", icon: HomeIcon },
        { ruta: "/#", nombre: "User", icon: UserGroupIcon },
      ],
    },
    {
      nombre: "mensTab",
      elements: [
        { ruta: "/#/messages", nombre: "Mensajes", icon: ChatIcon },
        { ruta: "/#", nombre: "Notificaciones", icon: BellIcon },
      ],
    },
  ];
  const getItems = (item) => {
    return contenido.find((element) => {
      return element.nombre === item;
    });
  };

  return (
    <div className='flex-1 px-4 space-y-2 overflow-hidden hover:overflow-auto'>
      {getItems(sidebar).elements.map((data, index) => {
        if (data) {
          return (
            <ItemNav
              icon={data.icon}
              key={index}
              ruta={data.ruta}
              nombre={data.nombre}
            />
          );
        }
        return null;
      })}
    </div>
  );
};
export default Items;
